import { useEffect } from 'react';
import { COUNTRY_NAME_BY_CURRENCY_MAP } from 'src/constants/country-name-by-currency-map';
import { MERCHANT_LIST_FOR_FINGERPRONT } from 'src/constants/merchant-list-for-fingerprint';
import { useAnalyticsContext } from 'src/context/analytics-context';
import { useAppContext } from 'src/context/store/app-provider';
import { useGetMerchantId } from 'src/context/store/use-get-merchant-id';
import { SegmentEventType, SnippetType } from 'src/types/segment';
import { getDeviceType } from 'src/utils/get-device-type';
import { getVisitorId } from 'src/utils/get-visitor-id';

export const useLogSnippetCartRenderedEvent = ({
  planSelected,
  snippetType,
}: {
  planSelected?: number;
  snippetType: SnippetType;
}) => {
  const { onSegmentLog } = useAnalyticsContext();
  const {
    currency,
    getExperiment,
    loading,
    publicKey,
    merchantCode,
    woo,
    merchant,
    experimentsStatus,
  } = useAppContext();
  const getMerchantId = useGetMerchantId();

  const experiment2281 = getExperiment('CT-2281-widgets') || 'control';

  const isExperiment2281 =
    experiment2281 !== 'control' &&
    !!merchant &&
    MERCHANT_LIST_FOR_FINGERPRONT.includes(merchant);

  useEffect(() => {
    const send = ({
      fingerprint,
      merchantId,
    }: {
      fingerprint?: Awaited<ReturnType<typeof getVisitorId>>;
      merchantId: string;
    }) => {
      onSegmentLog?.({
        event: SegmentEventType.SNIPPET_CART_RENDERED,
        publicKey: publicKey ? 'yes' : 'no',
        platformType: getDeviceType(),
        productType: 'installments',
        merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
        merchantCode,
        fingerprint,
        sourcePlugin: woo ? 'woo' : null,
        merchantId,
        planSelected,
        snippetType,
      });
    };

    if (loading) return;
    if (window.tabbyFingerprintSent) return;
    /**
     * It's necessary to check that experiments request is completed,
     * otherwise it's unusual behavior when 2 iframe events were sent (one could be lost)
     */
    if (experimentsStatus !== 'done') return;

    window.tabbyFingerprintSent = true;

    getMerchantId(currency).then((merchantId) => {
      if (isExperiment2281) {
        getVisitorId(experiment2281)
          .then((fingerprint) => {
            send({ merchantId, fingerprint });
          })
          .catch(() => {
            send({ merchantId });
          });
      } else {
        send({ merchantId });
      }
    });
  }, [
    onSegmentLog,
    publicKey,
    planSelected,
    snippetType,
    currency,
    merchantCode,
    loading,
    experiment2281,
    isExperiment2281,
    woo,
    getMerchantId,
    experimentsStatus,
  ]);
};
