// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__tabbySnippet--c10e5 {\n  --snippetTextColor: #292929;\n  --snippetLinkTextColor: #292929;\n}\n\n.styles__message--ed776 {\n  color: #16171d;\n  font-size: 14px;\n  font-style: normal;\n  line-height: 150%;\n}\n\n.styles__messagePayInto--cf5f4 {\n  color: #292929;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 21px;\n  letter-spacing: 0;\n}\n\n.styles__bold--e89dc {\n  font-weight: bold;\n}\n\n.styles__nowrap--cc89d {\n  text-wrap: \"nowrap\";\n}\n", "",{"version":3,"sources":["webpack://./src/components/default-promo-widget/styles.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,+BAA+B;AACjC;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".tabbySnippet {\n  --snippetTextColor: #292929;\n  --snippetLinkTextColor: #292929;\n}\n\n.message {\n  color: #16171d;\n  font-size: 14px;\n  font-style: normal;\n  line-height: 150%;\n}\n\n.messagePayInto {\n  color: #292929;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 21px;\n  letter-spacing: 0;\n}\n\n.bold {\n  font-weight: bold;\n}\n\n.nowrap {\n  text-wrap: \"nowrap\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabbySnippet": "styles__tabbySnippet--c10e5",
	"message": "styles__message--ed776",
	"messagePayInto": "styles__messagePayInto--cf5f4",
	"bold": "styles__bold--e89dc",
	"nowrap": "styles__nowrap--cc89d"
};
export default ___CSS_LOADER_EXPORT___;
