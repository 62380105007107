import React, { ReactElement } from 'react';

export const replaceStringTagToComponent = (
  string: string,
  wrapperMap: Record<string, (s: string) => ReactElement>
): any => {
  // Returning 'any' because it's hard to determine type due to various possible values.
  return string
    .split(/(<[a-z]+>[^<]+<\/[a-z]+>)/)
    .map((splittedTextValue, i) => {
      const [, tag, tagValue] =
        splittedTextValue.match(/<([a-z]+)>([^<]+)<\/[a-z]+>/) || [];

      return (
        <React.Fragment key={i}>
          {tag && wrapperMap[tag]
            ? wrapperMap[tag](tagValue)
            : splittedTextValue}
        </React.Fragment>
      );
    });
};
