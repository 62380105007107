class LocalStorage {
  private data = {};

  setItem(key: string, value: any) {
    this.data[key] = value.toString();
  }

  getItem(key: string) {
    // eslint-disable-next-line no-prototype-builtins
    return this.data.hasOwnProperty(key) ? this.data[key] : undefined;
  }

  removeItem(key: string) {
    delete this.data[key];
  }

  clear() {
    this.data = {};
  }
}

/*
  Prevents the insecure operation error in WKWebView
  https://developer.apple.com/forums/thread/87778
*/
const getStorageInstanse = () => {
  try {
    return window.localStorage;
  } catch {
    return new LocalStorage();
  }
};

export const localStorage = getStorageInstanse();
