import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import { WIDGET_INITIALIZED } from 'src/constants/common';
import { AppProvider, MerchantProps } from 'src/context/store/app-provider';
import { delayDecorator } from 'src/utils/delay-decorator';
import { renderToDecorator } from 'src/utils/render-to-decorator';

import { DefaultPromoWidget } from '../default-promo-widget';

export { DefaultPromoWidget as TabbyPaymentMethodSnippetCCI };

declare global {
  interface Window {
    /**
     * @deprecated keep for backward capability
     */
    TabbyPaymentMethodSnippetCCI: (
      props: { selector: string } & MerchantProps
    ) => void;
  }
}

window.TabbyPaymentMethodSnippetCCI = ({ selector, ...props }) => {
  datadogRum.addAction(WIDGET_INITIALIZED, {
    constructorName: 'TabbyPaymentMethodSnippetCCI',
    ...props,
  });

  delayDecorator(() => {
    renderToDecorator(
      selector,
      <AppProvider {...props}>
        <DefaultPromoWidget />
      </AppProvider>
    );
  });
};
