import { datadogRum } from '@datadog/browser-rum';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { lazily } from 'react-lazily';
import NumberFormat from 'react-number-format';
import { ConditionSuspense } from 'src/components/condition-suspense';
import { Portal } from 'src/components/portal';
import { WIDGET_INITIALIZED } from 'src/constants/common';
import { COUNTRY_NAME_BY_CURRENCY_MAP } from 'src/constants/country-name-by-currency-map';
import { INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO } from 'src/constants/learn-more';
import {
  AnalyticsProvider,
  useAnalyticsContext,
} from 'src/context/analytics-context';
import {
  AppProvider,
  MerchantProps,
  useAppContext,
} from 'src/context/store/app-provider';
import { useGetMerchantId } from 'src/context/store/use-get-merchant-id';
import { useLocale } from 'src/hooks/use-locale';
import { useLogSnippetCartRenderedEvent } from 'src/hooks/use-log-snippet-cart-rendered-event';
import { useOnExperimentsReady } from 'src/hooks/use-on-experiments-ready';
import { useTogglerState } from 'src/hooks/use-toggler-state';
import { SegmentEventType } from 'src/types/segment';
import { initializeDatadogRUMWidgets } from 'src/utils/datadog';
import { delayDecorator } from 'src/utils/delay-decorator';
import { getDeviceType } from 'src/utils/get-device-type';
import { getPriceSplitOf } from 'src/utils/get-price-split-of';
import { renderToDecorator } from 'src/utils/render-to-decorator';

import { replaceStringTagToComponent } from '../../utils/replace-string-tag-to-component';
import styles from './styles.css';

const { NonStandardPaymentPlansWidget } = lazily(
  () => import('src/components/non-standard-payment-plans-widget')
);
const { SnippetDefault } = lazily(
  () => import('src/components/snippet-default')
);
const { PopupLearnMoreWithSchedule } = lazily(
  () => import('src/components/popup-learn-more-with-schedule')
);
const { PopupLearnMorePayInFull } = lazily(
  () => import('src/components/popup-learn-more-pay-in-full')
);

const { LongTermPlans } = lazily(
  () => import('src/components/long-term-plans-widget')
);

const DD_STOP_RECORDING_TIMEOUT = 2000;

export function DefaultPromoWidget() {
  const { onSegmentLog, sendLearnMoreOpenEvent } = useAnalyticsContext();
  const {
    currency,
    price,
    installmentsCount,
    getExperiment,
    publicKey,
    merchantCode,
    offers,
    dispatch,
  } = useAppContext();
  const getMerchantId = useGetMerchantId();
  const pricePart = getPriceSplitOf(price, currency, installmentsCount);
  const [isShowPopup, { switchOn, switchOff }] = useTogglerState();
  const [condition, { switchOn: suspenseOn, switchOff: suspenseOff }] =
    useTogglerState();
  const t = useLocale();
  const experiment2170 = getExperiment('CT-2170-snippet-icons') || 'control';
  const experiment2115 = getExperiment('CT-2115-a-b-compliance') || 'control';
  const experiment2273 = getExperiment('CT-2273') || 'control';
  const experiment2214 = getExperiment('CT-2214') || 'control';
  const experiment2279 = getExperiment('CT-2279') || 'control';
  const experiment2431 = getExperiment('CT-2431-pay-now') || 'control';
  const isLongTermSnippetEnabled =
    getExperiment('CT-2832-long-terms') === 'test';
  const isPlansSwitcherSnippetEnabled =
    getExperiment('CT-3300-plans-switcher') === 'test';

  useOnExperimentsReady(initializeDatadogRUMWidgets);

  useLogSnippetCartRenderedEvent({
    planSelected: installmentsCount,
    snippetType: [3, 4].includes(installmentsCount)
      ? 'fullInformation'
      : 'notFullInformation',
  });

  const isNonDefaultPromo =
    !INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO.includes(installmentsCount) &&
    experiment2431 === 'control';

  const showLongTermWidget =
    (isLongTermSnippetEnabled || isPlansSwitcherSnippetEnabled) &&
    (Boolean(publicKey && merchantCode) || offers?.installments?.length > 0);

  useEffect(() => {
    if (isShowPopup) {
      onSegmentLog?.({
        event: SegmentEventType.LEARN_MORE_POP_UP_OPENED,
        publicKey: publicKey ? 'yes' : 'no',
        platformType: getDeviceType(),
        productType: 'installments',
        merchantIntegrationType: 'snippetAndPopup',
        planSelected: installmentsCount,
        popupType: 'standardWithoutInfo',
        merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
        merchantCode,
      });
    }
  }, [
    currency,
    installmentsCount,
    isShowPopup,
    merchantCode,
    onSegmentLog,
    publicKey,
  ]);
  const shouldShowPayInFull = condition && experiment2431 !== 'control';
  const shouldShowSchedule = condition && experiment2431 === 'control';

  useEffect(() => {
    if (shouldShowPayInFull) {
      dispatch({
        type: 'preparePayInFullPopup',
      });
    }
  }, [shouldShowPayInFull, dispatch]);

  useEffect(() => {
    if (shouldShowSchedule) {
      dispatch({
        type: 'prepareSchedulePopup',
      });
    }
  }, [shouldShowSchedule, dispatch]);

  const clickOnSnippet = () => {
    onSegmentLog?.({
      event: SegmentEventType.LEARN_MORE_CLICKED,
      publicKey: publicKey ? 'yes' : 'no',
      platformType: getDeviceType(),
      productType: 'installments',
      merchantIntegrationType: 'snippetAndPopup',
      planSelected: installmentsCount,
      snippetType: [3, 4].includes(installmentsCount)
        ? 'fullInformation'
        : 'notFullInformation',
      merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
      merchantCode,
    });

    datadogRum.startSessionReplayRecording();
  };

  const clickSnippetHandler = () => {
    switchOn();
    suspenseOn();
    clickOnSnippet();

    getMerchantId(currency).then((merchantId) => {
      sendLearnMoreOpenEvent(merchantId);
    });
  };

  const close = (action: 'cross' | 'inactive zone' | 'continue') => {
    onSegmentLog?.({
      event: SegmentEventType.LEARN_MORE_POP_UP_CLOSED,
      publicKey: publicKey ? 'yes' : 'no',
      platformType: getDeviceType(),
      productType: 'installments',
      merchantIntegrationType: 'snippetAndPopup',
      planSelected: installmentsCount,
      popupType: 'standardWithInfo',
      merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
      action,
      merchantCode,
    });
    switchOff();
    // recording starts when we are opening Learn More, stop when we close Learn More
    // we wait for a while just to see how our widget looks (without Learn More)
    setTimeout(() => {
      datadogRum.startSessionReplayRecording();
      datadogRum.stopSession();
    }, DD_STOP_RECORDING_TIMEOUT);
  };

  const params = (newPrice) => ({
    price: newPrice,
    currency,
    installmentsCount,
  });

  const getText = (formattedValue: string) => {
    switch (true) {
      case experiment2431 === 'group1':
        return INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO.includes(
          installmentsCount
        ) ? (
          <span className={styles.messagePayInto}>
            {t('default.split_into', {
              installmentsCount,
            })}
          </span>
        ) : (
          <span className={styles.messagePayInto}>
            {t('split_your_purchase')}
          </span>
        );
      case experiment2431 === 'group2':
        return (
          <span className={styles.messagePayInto}>
            {t('default.pay_with_tabby')}
          </span>
        );
      case experiment2214 === 'test':
        return (
          <span className={styles.message}>
            {replaceStringTagToComponent(
              t('default.interestFreeShariah', params(formattedValue)),
              {
                strong: (string) => (
                  <strong className={styles.nowrap}>{string}</strong>
                ),
              }
            )}
          </span>
        );
      case experiment2279 === 'test':
        return (
          <span className={styles.bold}>
            {replaceStringTagToComponent(
              t('default.interestFreeWithoutOr', params(formattedValue)),
              {
                s: (string) => <span className={styles.nowrap}>{string}</span>,
              }
            )}
          </span>
        );
      case experiment2170 === 'test' && currency !== 'SAR':
        return (
          <span className={styles.bold}>
            {replaceStringTagToComponent(
              t('default.without_shariah_compliant', params(formattedValue)),
              {
                strong: (string) => (
                  <strong className={styles.nowrap}>{string}</strong>
                ),
              }
            )}
          </span>
        );
      case experiment2115 === 'test':
        return replaceStringTagToComponent(
          t('default.without_shariah_compliant', params(formattedValue)),
          {
            strong: (string) => <strong>{string}</strong>,
          }
        );
      case experiment2273 === 'group1' && currency === 'SAR':
        return (
          <span className={cn(styles.message, styles.bold)}>
            {t('default.interestFree', params(formattedValue))}
          </span>
        );
      case experiment2273 === 'group2' && currency === 'SAR':
        return (
          <span className={cn(styles.message, styles.bold)}>
            {t('default.interestFreeNoFees', params(formattedValue))}
          </span>
        );
      default:
        return replaceStringTagToComponent(
          t('default.shariah_compliant', params(formattedValue)),
          {
            strong: (string) => <strong>{string}</strong>,
          }
        );
    }
  };

  if (isNonDefaultPromo) {
    return (
      <ConditionSuspense condition={isNonDefaultPromo}>
        <NonStandardPaymentPlansWidget />
      </ConditionSuspense>
    );
  }

  if (showLongTermWidget) {
    return (
      <ConditionSuspense condition={showLongTermWidget}>
        <LongTermPlans />
      </ConditionSuspense>
    );
  }

  return (
    <div className={styles.tabbySnippet}>
      <ConditionSuspense condition={!isNonDefaultPromo}>
        <SnippetDefault
          message={
            <NumberFormat
              /* Need key to recall renderText with new pricePart */
              key={pricePart}
              value={pricePart}
              renderText={getText}
              displayType="text"
              allowNegative={false}
            />
          }
          linkText={t('default.learnMore')}
          onClick={clickSnippetHandler}
        />
      </ConditionSuspense>

      <Portal to="body">
        <ConditionSuspense condition={shouldShowPayInFull}>
          <PopupLearnMorePayInFull
            isShow={isShowPopup}
            onClose={() => close('cross')}
            onContinue={() => close('continue')}
            onInactiveZoneClick={() => close('inactive zone')}
            onCloseAfter={suspenseOff}
          />
        </ConditionSuspense>

        <ConditionSuspense condition={shouldShowSchedule}>
          <PopupLearnMoreWithSchedule
            isShow={isShowPopup}
            onClose={() => close('cross')}
            onContinue={() => close('continue')}
            onInactiveZoneClick={() => close('inactive zone')}
            onCloseAfter={suspenseOff}
          />
        </ConditionSuspense>
      </Portal>
    </div>
  );
}

declare global {
  interface Window {
    TabbyPromoDefault: (props: { selector: string } & MerchantProps) => void;
  }
}

window.TabbyPromoDefault = ({ selector, ...props }) => {
  datadogRum.addAction(WIDGET_INITIALIZED, {
    constructorName: 'TabbyPromoDefault',
    ...props,
  });

  delayDecorator(() => {
    renderToDecorator(
      selector,
      <AppProvider {...props}>
        <AnalyticsProvider props={props}>
          <DefaultPromoWidget />
        </AnalyticsProvider>
      </AppProvider>
    );
  });
};
