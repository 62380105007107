import Cookies from 'js-cookie';
import { ExperimentRecord } from 'src/types/experiment';
import { localStorage } from 'src/utils/local-storage';

const FINGERPRINT_ID_KEY = 'owjojo1123pok' as const;

declare global {
  interface Window {
    CloudCookie: any;
    [FINGERPRINT_ID_KEY]?: string;
  }
}

const getVisitorIdFromFingerprintjsPro = async () => {
  const fingerprintJsPro = await import('@fingerprintjs/fingerprintjs-pro');
  const fp = await fingerprintJsPro.load({
    apiKey: process.env.FINGER_PRINT_KEY ?? '',
    region: 'eu',
    endpoint: 'https://meter.checkout.tabby.ai',
    scriptUrlPattern:
      'https://meter.checkout.tabby.ai/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
  });
  const { visitorId } = await fp.get();
  return visitorId;
};

const saveFingerprint = (entityId?: string) => {
  if (entityId) {
    window[FINGERPRINT_ID_KEY] = entityId;
    localStorage.setItem(FINGERPRINT_ID_KEY, entityId);
    Cookies.set(FINGERPRINT_ID_KEY, entityId);
  } else {
    window[FINGERPRINT_ID_KEY] = undefined;
    localStorage.removeItem(FINGERPRINT_ID_KEY);
    Cookies.remove(FINGERPRINT_ID_KEY);
  }
};
const getFingerprint = () => {
  let entity: string | undefined = window[FINGERPRINT_ID_KEY];

  if (entity) return entity;

  try {
    entity = localStorage.getItem(FINGERPRINT_ID_KEY) as string;
    if (entity) return JSON.parse(entity);

    entity = Cookies.get(FINGERPRINT_ID_KEY);
    if (entity) return JSON.parse(entity);
  } catch (er) {
    // eslint-disable-next-line no-console
    console.error(er);
  }

  return undefined;
};

export const getVisitorId = async (
  experiment: ExperimentRecord['CT-2281-widgets']
) => {
  switch (experiment) {
    case 'C': {
      const fingerprint = getFingerprint();
      if (fingerprint) return fingerprint;
      const entityId = await getVisitorIdFromFingerprintjsPro();
      saveFingerprint(entityId);
      return entityId;
    }
    default:
      saveFingerprint();
      return undefined;
  }
};
